import {ApplicationCancelTokenSource, ApplicationContext, SingleConversationContext, NoConversationContext, MultiConversationsContext, MessageContext} from '@frontapp/ui-bridge';
import {Observable} from 'rxjs';

/*
 * General types.
 */

type InternalContextProperties = 'hostId' | 'entryPointId';
type WebViewContextFunctions = Pick<ApplicationContext, FunctionPropertyNames<ApplicationContext>>;

type WebViewSingleConversationContext = Omit<SingleConversationContext, InternalContextProperties>;
type WebViewNoConversationContext = Omit<NoConversationContext, InternalContextProperties>;
type WebViewMultipleConversationsContext = Omit<MultiConversationsContext, InternalContextProperties>;
type WebViewMessageContext = Omit<MessageContext, InternalContextProperties>;

export type WebViewContext = WebViewSingleConversationContext | WebViewNoConversationContext | WebViewMultipleConversationsContext | WebViewMessageContext;

export type WebViewBaseBridge = {
  buildCancelTokenSource(): ApplicationCancelTokenSource;
  contextUpdates: Observable<WebViewContext>;
  isCancelError(error: any): boolean;
};
export type WebViewBridge = WebViewBaseBridge & WebViewContextFunctions;

export enum WebViewMessageTypesEnum {
  CONTEXT_UPDATE = 'context_update',
  FUNCTION_CALL = 'function_call',
  FUNCTION_CANCEL = 'function_cancel',
  FUNCTION_RESULT = 'function_result',
  FUNCTION_ERROR = 'function_error'
}

/*
 * App SDK to WebView.
 */

interface WebViewContextUpdateMessage {
  type: WebViewMessageTypesEnum.CONTEXT_UPDATE;
  context: WebViewContext;
}

interface WebViewFunctionResultMessage {
  type: WebViewMessageTypesEnum.FUNCTION_RESULT;
  id: string;
  value: unknown;
}

interface WebViewFunctionErrorMessage {
  type: WebViewMessageTypesEnum.FUNCTION_ERROR;
  id: string;
  error: string;
}

export type WebViewHostMessage =
  | WebViewContextUpdateMessage
  | WebViewFunctionResultMessage
  | WebViewFunctionErrorMessage;

export type WebViewHostMessageWithPort = WebViewHostMessage & {port: MessagePort};
type WebViewContextUpdateMessageWithPort = WebViewContextUpdateMessage & {port: MessagePort};

/*
 * WebView to AppSDK.
 */

export const webViewBridgeHandshake = '@frontapp/ui-sdk#WebViewBridge.handshake';

interface WebViewFunctionCallMessage {
  type: WebViewMessageTypesEnum.FUNCTION_CALL;
  id: string;
  contextId: string;
  name: string;
  args: ReadonlyArray<unknown>;
}

interface WebViewFunctionCancelMessage {
  type: WebViewMessageTypesEnum.FUNCTION_CANCEL;
  id: string;
}

export type WebViewSdkMessage = WebViewFunctionCallMessage | WebViewFunctionCancelMessage;

/*
 * Type guards.
 */

export function isContextUpdateMessageWithPort(
  message: WebViewHostMessage
): message is WebViewContextUpdateMessageWithPort {
  return message.type === WebViewMessageTypesEnum.CONTEXT_UPDATE;
}

export function isFunctionResultMessage(
  message: WebViewHostMessage
): message is WebViewFunctionResultMessage | WebViewFunctionErrorMessage {
  return (
    message.type === WebViewMessageTypesEnum.FUNCTION_RESULT ||
    message.type === WebViewMessageTypesEnum.FUNCTION_ERROR
  );
}

/*
 * Type helpers
 */

/** Filter a type to only keep functions properties. */
type FunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends never ? never : T[K] extends (...args: any) => any ? K : never;
}[keyof T];
